import React from 'react';
import Button from '../../Button';
import './Contact.scss';

const Contact: React.FC = () => {
  return (
    <div className="contact-container">
      <div className="contact-left">
        <img src="/transparent-logo.png" alt="MedAux" className="contact-logo" />
        <Button />
      </div>
      <div className="contact-right">
        <div className="client-logos">
          <img src="/groupe-champlain.png" alt="Groupe Champlain" className="client-logo" />
          <img src="/vigisante.png" alt="Vigi Santé" className="client-logo" />
          <img src="/chsld-sainte-catherine.jpg" alt="CHSLD Sainte-Catherine" className="client-logo" />
        </div>
        <div className="contact-info">
          <h2>CONTACTEZ-NOUS</h2>
          <div className="contact-detail">
            <h3>Adresse</h3>
            <p>1235 rue notre-dame ouest, unité 382, Montréal, Qc H3C 0B1</p>
          </div>
          <div className="contact-detail">
            <h3>Courriel</h3>
            <p>reception@agencemedaux.com</p>
          </div>
          <div className="contact-detail">
            <h3>Téléphone</h3>
            <p>(438) 439-9917</p>
          </div>
          <div className="social-icons" id='Contact'>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
              <img src="/instagram.svg" alt="Instagram" />
            </a>
            <a href="https://www.facebook.com/profile.php?id=61554544113228" target="_blank" rel="noopener noreferrer">
              <img src="/facebook.svg" alt="Facebook" />
            </a>
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
              <img src="/linked-in.svg" alt="LinkedIn" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

import React from 'react';
import './About.scss';

const About: React.FC = () => {
    return (
        <div className="about-us-section" id='About'>
            <div className="about-us-header">
                <img src="/logo-simplified.png" alt="MedAux Logo" className="about-us-logo" />
                <h2>L'équipe MedAux</h2>
            
            <div className="about-us-body">
            <p>
                Au service de la population québécoise, MedAux a été fondée par des
                professionnels de la santé qui ont opéré dans le réseau de la santé depuis
                1994. Notre engagement indéfectible envers l'excellence, notre profonde
                empathie envers les professionnels de la santé, et notre reconnaissance
                constante de leur contribution précieuse sont au cœur de notre identité.
            </p>
            <p>
                Forts de nos années d'expérience et de notre compréhension approfondie
                des défis et des besoins du secteur de la santé et des services sociaux, nous
                avons créé MedAux pour répondre à un besoin crucial : établir un pont
                entre les professionnels de la santé dévoués et les établissements de santé
                qui recherchent des solutions de personnel temporaires de haute qualité.
            </p>
            <p>
                Notre équipe dévouée travaille sans relâche 24h/7j pour mettre en relation
                les compétences exceptionnelles des professionnels de la santé avec les
                besoins immédiats des établissements de santé.
            </p>
            <p>
                Chez MedAux, nous sommes bien plus qu'une agence de placement
                temporaire ; nous sommes un partenaire engagé dans l'amélioration de la
                santé et du bien-être de notre communauté. Rejoignez-nous dans cette
                mission essentielle.
            </p>
            </div>
        </div>
            <div className="about-us-image">
                <img src="team-image.svg" alt="Team MedAux" />
            </div>
        </div>
    );
}

export default About;

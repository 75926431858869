import React, { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import emailjs from 'emailjs-com';
import 'moment/locale/fr';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './RequestForm.scss';

const localizer = momentLocalizer(moment);

interface FormData {
  institutionName: string;
  institutionAddress: string;
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  contactPhone: string;
  contactRole: string;
}

interface Shift {
  id: number;
  start: Date;
  end: Date;
  title: string;
  professionalType: string;
  numberOfProfessionals: number;
}

const messages = {
  previous: '<',
  next: '>',
  today: "Aujourd'hui",
  week: 'Semaine',
  day: 'Jour'
};

const RequestForm: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    institutionName: '',
    institutionAddress: '',
    contactFirstName: '',
    contactLastName: '',
    contactEmail: '',
    contactPhone: '',
    contactRole: '',
  });

  const handleInvalid = (event: React.InvalidEvent<HTMLInputElement>) => {
    switch(event.target.name) {
      case "institutionName":
        event.target.setCustomValidity("Veuillez entrer le nom de l'institution.");
        break;
      case "institutionAddress":
        event.target.setCustomValidity("Veuillez entrer l'adresse de l'institution.");
        break;
      case "contactFirstName":
        event.target.setCustomValidity("Veuillez entrer votre prénom");
        break;  
      case "contactLastName":
        event.target.setCustomValidity("Veuillez entrer votre nom");
        break;  
      case "contactEmail":
        event.target.setCustomValidity("Veuillez entrer une adresse e-mail valide.");
        break;
      case "contactPhone":
        event.target.setCustomValidity("Veuillez entrer un numéro de téléphone valide.");
        break;
      case "contactRole":
        event.target.setCustomValidity("Veuillez entrer votre rôle dans l'organisation");
        break;
    }
  };
  

  const [shifts, setShifts] = useState<Shift[]>([]);

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    event.target.setCustomValidity("");
  };

  const handleSelectSlot = ({ start, end }: { start: Date; end: Date }) => {

    if (start < new Date()) {
      alert("Vous ne pouvez pas sélectionner une date passée.");
      return; 
    }

    const newShift: Shift = {
      id: shifts.length + 1,
      start,
      end,
      title: "Quart " + (shifts.length + 1),
      professionalType: 'infirmière',
      numberOfProfessionals: 1
    };
    const updatedShifts = [...shifts, newShift].sort((a, b) => a.start.getTime() - b.start.getTime());
    setShifts(updatedShifts);
  };

  const handleDeleteShift = (shiftId: number) => {
    if (window.confirm('Voulez-vous vraiment supprimer ce quart?')) {
      setShifts(shifts.filter(shift => shift.id !== shiftId));
    }
  };

  const updateShift = (shiftId: number, key: keyof Shift, value: any) => {
    setShifts(shifts.map(shift => 
      shift.id === shiftId ? { ...shift, [key]: value } : shift
    ));
  };

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID || 'default_service_id';
  const templateIdRequester = process.env.REACT_APP_EMAILJS_TEMPLATE_ID_REQUESTER || 'default_template_id_requester';
  const templateIdInternal = process.env.REACT_APP_EMAILJS_TEMPLATE_ID_INTERNAL || 'default_template_id_internal';
  const userId = process.env.REACT_APP_EMAILJS_USER_ID || 'default_user_id';

  const sendEmail = () => {
    const requesterEmailParams = {
      contact_name: `${formData.contactFirstName} ${formData.contactLastName}`,
      contact_email: formData.contactEmail,
      institution_name: formData.institutionName,
      shifts: shifts.map(shift => `Début: ${moment(shift.start).format('LLLL')}, Fin: ${moment(shift.end).format('LLLL')}, Type de professionnel: ${shift.professionalType}, Nombre de professionnels: ${shift.numberOfProfessionals}`).join("\n")
    };

    const internalEmailParams  = {
      contact_name: `${formData.contactFirstName} ${formData.contactLastName}`,
      contact_email: formData.contactEmail,
      address: formData.institutionAddress,
      institution_name: formData.institutionName,
      institution_address: formData.institutionAddress,
      phone: formData.contactPhone,
      role: formData.contactRole,
      shifts: shifts.map(shift => `Début: ${moment(shift.start).format('LLLL')}, Fin: ${moment(shift.end).format('LLLL')}, Type de professionnel: ${shift.professionalType}, Nombre de professionnels: ${shift.numberOfProfessionals}`).join("\n")
    };

    setSuccessMessage('');
    setErrorMessage('');
    setIsSubmitted(true);
    
    Promise.all([
      emailjs.send(serviceId, templateIdRequester, requesterEmailParams, userId),
      emailjs.send(serviceId, templateIdInternal, internalEmailParams, userId)
    ])
    .then((responses) => {
      setSuccessMessage('Votre demande a été envoyée avec succès. Vous recevrez bientôt un email de confirmation.');
    })
    .catch((error) => {
      setErrorMessage('Une erreur interne s’est produite, veuillez nous contacter au 450-350-2350 pour une demande. Désolé pour le désagrément.');
    });
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (shifts.length === 0) {
      alert("Veuillez sélectionner au moins un quart avant de soumettre votre demande.");
      return;
    }
    sendEmail();
  };

  return (
      <div className="request-form-container">
      <img src="transparent-logo.png" alt="MedAux Logo" className="company-logo" />
      <h1>Contactez l'équipe MedAux</h1>
      <h2>Nous simplifions les remplacements grâce à la technologie!</h2>
      {isSubmitted ? (
        <div className={`message-container ${successMessage ? 'success' : 'error'}`}>
          <h2>{successMessage || errorMessage}</h2>
        </div>
      ) : (
      <form onSubmit={handleSubmit}>
          <div className="form-layout">
            <div className="form-column">
                <input type="text" name="institutionName" placeholder="Nom de l'institution" onChange={handleInputChange} onInvalid={handleInvalid} required />
                <input type="text" name="institutionAddress" placeholder="Adresse de l'institution" onChange={handleInputChange} onInvalid={handleInvalid} required />
                <input type="text" name="contactFirstName" placeholder="Prénom du demandeur" onChange={handleInputChange} onInvalid={handleInvalid} required />
                <input type="text" name="contactLastName" placeholder="Nom du demandeur" onChange={handleInputChange} onInvalid={handleInvalid} required />
                <input type="email" name="contactEmail" placeholder="Courriel" onChange={handleInputChange} onInvalid={handleInvalid} required />
                <input type="tel" name="contactPhone" pattern="\d*" placeholder="Numéro de téléphone" onChange={handleInputChange} onInvalid={handleInvalid} required />
                <input type="text" name="contactRole" placeholder="Rôle dans l'organisation" onChange={handleInputChange} onInvalid={handleInvalid} required />
            </div>
            <div className="calendar-column">
                <ul>
                  <li>Sélectionnez sur le calendrier le quart pour lequel vous avez besoin d'un remplacement.</li>
                  <li>Ajoutez les détails dans la ligne créée pour le type de ressource dont vous avez besoin et le nombre de personnel pour ce quart.</li>
                  <li>Lorsque tous vos quarts ont été sélectionnés et ajustés, soumettez la demande!</li>
                  <li>Vous recevrez un courriel de confirmation, s.v.p surveiller vos courriers indésirables.</li>
                </ul>
                <Calendar 
                messages={messages}
                localizer={localizer}
                events={shifts}
                startAccessor="start"
                endAccessor="end"
                selectable={true}
                views={['week', 'day']}
                defaultView='week'
                onSelectSlot={handleSelectSlot}
                style={{ height: 500 }}
                culture = 'fr'
                />
            </div>
          </div>
          <table>
            <thead>
                <tr>
                  <th>Début</th>
                  <th>Fin</th>
                  <th>Type de professionnel</th>
                  <th>Nombre de professionnels</th>
                  <th>Supprimer</th>
                </tr>
            </thead>
            <tbody>
                {shifts.map(shift => (
                <tr key={shift.id}>
                  <td>{moment(shift.start).locale('Fr').format('Do MMMM YYYY, h:mm a')}</td>
                  <td>{moment(shift.end).locale('Fr').format('Do MMMM YYYY, h:mm a')}</td>
                  <td>
                      <select value={shift.professionalType} onChange={e =>
                        updateShift(shift.id, 'professionalType', e.target.value)}>
                        <option value="infirmière">Infirmière</option>
                        <option value="accompagnant">Préposé aux bénéficiaires</option>
                        <option value="nettoyage">Préposé à l'entretien</option>
                      </select>
                  </td>
                  <td>
                      <input type="number" value={shift.numberOfProfessionals} min="1" onChange={e => updateShift(shift.id, 'numberOfProfessionals', parseInt(e.target.value))}/>
                  </td>
                  <td>
                      <button className="delete-button" onClick={() => handleDeleteShift(shift.id)}>Supprimer</button>
                  </td>
                </tr>
                ))}
            </tbody>
          </table>
          <div className="form-actions">
            <button type="submit">Soumettre la demande</button>
          </div>
      </form>)}
    </div>
  );
};

export default RequestForm;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Button.scss';

const HeroButton: React.FC = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/requestform'); // Replace '/requestform' with the actual path to your RequestForm component
    }

    return (
        <button className="hero-button" onClick={handleClick}>COMBLER UN QUART</button>
    );
}

export default HeroButton;

import React from 'react';
import HeroSection from './HeroSection/HeroSection';
import Mission from './Mission/Mission';
import About from './About/About';
import Services from './Services/Service';
import Contact from './Contact/Contact';

const HomePage: React.FC = () => {
    return (
        <div className="home-page">
            <HeroSection />
            <Mission />
            <About />
            <Services />
            <Contact />
        </div>
    );
}

export default HomePage;

import React from 'react';
import Button from '../../Button'
import './Mission.scss';

const Mission: React.FC = () => {
    return (
        <div className="mission-section" id='Mission'>
            <div className="mission-logo">
                <img src="logo-simplified.png" alt="MedAux Logo" className="medaux-logo" />
            </div>
            <h2 className="mission-title">Sauver des vies un quart à la fois</h2>
            <p className="mission-statement">
                Chez MedAux, notre mission est de soutenir le réseau de la santé et des services sociaux en offrant des soins de qualité aux citoyens, tout en offrant aux professionnels de la santé un contrôle sur leur temps et leur revenu.
            </p>
            <Button />
        </div>
    );
}

export default Mission;

import React from 'react';
import './Services.scss';

const Services: React.FC = () => {
    return (
        <div className='Container' id='Services' style={{ position: 'relative' }}>
            <div/>
            <div/>
            <div className="services-section">
                <h2>NOS SERVICES</h2>
                <ul className="services-list">
                    <li>Soins infirmiers</li>
                    <li>Soins infirmiers auxiliaires</li>
                    <li>Préposé aux bénéficiaires</li>
                    <li>Entretien ménager</li>
                </ul>
            </div>
        </div>
    );
}

export default Services;

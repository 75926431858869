import React from 'react';
import './HeroSection.scss';
import Button from '../../Button';


const HeroSection: React.FC = () => {
    return (
        <div className="hero-section"  id="Home">
            <div className="text-column">
                <img src="transparent-logo.png" alt="MedAux Logo" className="logo" />
                <h1 className="main-header">Placement en santé</h1>
                <h2 className="sub-header">Là où l'excellence rencontre la reconnaissance.</h2>
                <Button />
            </div>
            <div className="image-column">
                <img src="female-nurse.jpg" alt="Female Nurse" className="nurse-image" />
            </div>
        </div>
    );
}

export default HeroSection;

// Navbar.tsx
import React, { useState } from 'react';
import './Navbar.scss';
import { useNavigate } from 'react-router-dom';

const Navbar: React.FC = () => {
    const [isNavExpanded, setIsNavExpanded] = useState(false);
    const navigate = useNavigate();

    const scrollToSection = (sectionId: string) => {
        if (window.location.pathname !== '/') {
            // Navigate to the homepage
            navigate('/');

            // Wait for the navigation to complete, then scroll to the section
            setTimeout(() => {
                document.querySelector(sectionId)?.scrollIntoView({ behavior: 'smooth' });
            }, 0);
        } else {
            // If already on the homepage, just scroll to the section
            document.querySelector(sectionId)?.scrollIntoView({ behavior: 'smooth' });
        }
        setIsNavExpanded(false);
    };
    

    return (
        <nav className="navbar">
            <button
                className="hamburger"
                onClick={() => setIsNavExpanded(!isNavExpanded)}
            >
                <div></div>
                <div></div>
                <div></div>
            </button>
            <ul className={`nav-links ${isNavExpanded ? 'expanded' : ''}`}>
                <li><button onClick={() => scrollToSection('#Home')}>Accueil</button></li>
                <li><button onClick={() => scrollToSection('#Mission')}>Mission</button></li>
                <li><button onClick={() => scrollToSection('#About')}>À propos</button></li>
                <li><button onClick={() => scrollToSection('#Services')}>Services</button></li>
                <li><button onClick={() => scrollToSection('#Contact')}>Contact</button></li>
            </ul>
        </nav>
    );
}

export default Navbar;

